@import url("https://fonts.googleapis.com/css2?family=Martel:wght@200;300;400;600;700&family=Noto+Serif&family=Tiro+Devanagari+Hindi&family=Titan+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Devanagari:wght@300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Bungee+Spice&display=swap");

* {
  margin: 0;
  padding: 0;
}

ul  {
  list-style: disc;
  padding-left: 16px;
}
ol  {
  list-style: decimal;
  padding-left: 16px;
}

#exportContent{
  font-family: 'Noto Sans' !important;
}

table,
td,
tr {
  border: 1px solid black !important;
  padding: 2px 4px;
}

sub,
sup {
  line-height: 2 !important;
}

p:lang(EN-US),
span:lang(EN-US),
div:lang(EN-US) {
  font-family: serif !important;
}

.visibilityCheckbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.visibilityCheckbox label {
  font-weight: bold;
  font-family: serif !important;
  padding: 0px 6px;
}

/* Header Styling Start */
.Header {
  /* border: 10px double black; */
  display: flex;
  margin-bottom: 4px;
  border-radius: 2px;
  opacity: 1;
  padding: 6px 4px;
}

.details {
  width: 100%;
}

#institution_name {
  /* font-family: "Bungee Spice", cursive; */
  border-radius: 2px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 32px;
  color: white;
  margin: 0px;
  text-align: center;
}

#other_header_details {
  display: flex;
  justify-content: center;
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  text-align: center;
}

#contact,
#instituted_by,
#pdf_name {
  padding: 4px 16px;
  border-radius: 1px;
  border: 2px solid black;
  border-radius: 100px;
  margin-right: 4px;
  font-family: "Noto Serif";
}

.company-logo,
#download-qr {
  border-radius: 50px;
  /* margin: 4px; */

  margin-top: 5px;
  border: 10px double solid;
  margin-bottom: 0px;
}

.image-and-tagline {
  display: grid;
  text-align: center;
}

.image-and-tagline span {
  font-size: 4.5px;
  font-family: "Noto Serif", serif;
}

.single_option label {
  /* display: flex; */
  color: #300454;
  font-weight: 700;
  align-items: center;
}

.shortAnswerBody {
  width: 21cm;
  /* min-height: 29.7cm; */
}

.watermark {
  position: fixed;
  opacity: 0.2;
  margin-top: 24vh;
  margin-left: 20vw;
  /* transform: rotate(-45deg); */
  z-index: 1;
  pointer-events: none;
}

/* Header Styling End */
/* ------------------------------- */
/* Content Styling Start */

@media print {
  .content {
    width: auto !important;
    min-height: auto !important;
    column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    padding: 0px 4px;
    column-gap: 20px;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-rule: 1px solid #300454;
    -webkit-column-rule: 1px solid #300454;
    -moz-column-rule: 1px solid #300454;
  }

  .watermark {
    display: block;
  }

  .Options {
    break-inside: avoid;
  }
}

#Footer {
  width: 900px !important;
}

.Solution {
  max-width: 440px;
}

/* } */

/* Footer Styling Start */
#Footer {
  width: 100vw;
  background-color: yellow;
}

#Footer p {
  margin: 0;
  line-height: 16px;
  text-align: initial;
  font-weight: bold;
  font-family: "Poppins";
  margin-top: "8px";
  font-size: "10px";
  padding-left: 20px;
  position: relative;
}

/* Footer Styling End */

/* Question Styling Start */
.indexPPT {
  margin-right: 14px !important;
}

.indexPDF {
  font-weight: bold;
  /* margin-right: 10px;
  border: 2px solid #300454;
  background: #300454;
  color: white; */
}

.Questions p,
.Questions span {
  /* font-family: "Tiro Devanagari Hindi" !important; */
  font-family: "Noto Serif Devanagari" !important;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  opacity: 1;
  font-weight: 700;
}

#previous_year_tag {
  font-family: "Poppins" !important;
}

.hindi-question-paragraph {
  color: black;
}

p {
  margin: 2px 0px !important;
}

.Questions img {
  /* max-width: 30vw; */
}

.single_question {
  display: flex;
  justify-content: start;
  align-items: baseline;
  padding: 1px 1px 1px 4px;
}

.single_question img{
  display: inline;
}

/* Question Styling End */

/* Options Styling Start */
input[type="radio"] {
  border: 0px;
  height: 8px;
  margin: 0px 1px;
}

.Options {
  display: flex;
  color: black;
  margin-left: 20px;
  font-family: "Noto Serif Devanagari", serif;
  opacity: 1;
}

.firstRow,
.secondRow {
  width: 20.8vw;
  font-family: "Noto Serif Devanagari", serif;
  font-weight: 700;
}

.firstRow {
  margin-left: 12px;
}

.single_option {
  /* display: flex; */
  display: block;
  align-items: center;
  width: fit-content; 
  padding: 0px 4px;
  border-radius: 4px;
}

.single_option img {
  /* max-width: 80px; */
  margin: 2px;
  border-radius: 1px;
}

.optionName {
  font-family: "Noto Serif Devanagari", Times, serif;
  font-weight: 700;
  color: #300454;
}

/* Options Styling End */

/* Solution Styling Code Start */
.Solution {
  border: 1px solid #300454 !important;
  margin: 10px 6px;
  font-family: "Noto Serif Devanagari", serif;
  /* padding: 4px 4px 4px 8px; */
  border-radius: 2px;
  font-weight: 600;
}

.Solution img {
  /* max-width: 30vw; */
}

.Solution p {
  margin: 8px 0px !important;
}

/* Solution Styling Code End */

/* Sweetalert Styling Code Start */
.swal2-confirm {
  width: 440px;
  text-align: center;
  font-family: "Tiro Devanagari Hindi", serif;
}

.swal2-input {
  font-family: "Tiro Devanagari Hindi", serif;
}

/* Sweetalert Styling Code End */

/* Mathjax Styling Code Start */
mjx-container[jax="SVG"][display="true"] {
  display: inline-block !important;
  margin: 1px !important;
}

.MathJax {
  /* display: flex; */
  font-weight: normal !important;
  /* margin: 4px; */
  margin: 2px;
}

.customQuestionMathAJAX .MathJax {
  display: inline-flex !important;
  align-items: center;
  vertical-align: middle;
}

/* Mathjax Styling Code End */

/*custom css for display paper PC */
.single_question p{
  display: inline;
}
.single_question img{
  display: inline;
  /* width: 100%; */
}

.single_option label p,.single_option label p img, .single_option label img{
  display: inline-block;
  vertical-align: middle;
}

mjx-container[jax="SVG"] > svg {
  display: inline !important;
}
