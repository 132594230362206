@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    /*background-color: #001E6C;*/
    background-color: #120e43;
}

.c-font{
    font-family: 'Rubik', sans-serif;
}
.c-font1{
    font-family: 'Poppins', sans-serif;
}
.c-font2{
    font-family: serif;
}
.c-font-bold{
    font-weight: bolder !important;
}
.bg-card{
    background: rgb(45,232,134);
    background: linear-gradient(0deg, rgba(45,232,134,0.6881346288515406) 0%, rgba(10,158,81,0.9962578781512605) 100%);
}


.hero1{
    background-color: #1a68d3;
    color: white;
    background-image: radial-gradient(ellipse at bottom,#1a68d3 5%,transparent 60%),linear-gradient(136deg,transparent,#71c7ee 290%),linear-gradient(115deg,#9d9af1,transparent 40%),linear-gradient(180deg,transparent 0,#1a68d3 70%),radial-gradient(ellipse at -70% -180%,transparent 80%,#71c7ee 0),radial-gradient(ellipse at bottom,#1a68d3 40%,transparent 80%),radial-gradient(ellipse at 5% 340%,transparent 80%,#bfc2f4 0)
}
.hero2{
    background-image: linear-gradient(90deg,#ff8485,#f9eac6);
}
.ccard{
    transition: background-color 0.5s ease; /* Add transition effect */
}
.ccard:hover {
    background: linear-gradient(to bottom right, #1e90ff, #00ced1); /* Set background gradient on hover */
}
.c-card{
    display: block;
    background: linear-gradient(to right, #001E6C 50%, #035397 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .2s ease-out;
}
.c-card:hover {
    background-position: left bottom;
}


.c-card1{
    display: block;
    background: linear-gradient(to left, white 50%, #5da3fa 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all 0.1s ease-out;
}
.c-card1:hover {
    background-position: left bottom;
}
.c-shadow{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.c-input{
    --tw-border-opacity:0.2;
}
.c-input:focus{
    box-shadow: none;
    border: none;
}
.crd:hover{
    background: rgb(45,232,134);
    background: linear-gradient(0deg, rgba(45,232,134,0.6881346288515406) 0%, rgba(93,163,250,1) 100%);
}
@media print {
    .div-to-hide {
        display: none;
    }
}
.c-shadow1{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
a{
    text-decoration: none !important;
}
/* width */
::-webkit-scrollbar {
    width: 2px !important;
    height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.c-border{
    border: #120e43 solid 1px !important;
}
.new-c-font{
    font-family: "Noto Serif Devanagari" !important;
}
.c-size{
    font-size: 0.875rem !important; /* 14px */
    line-height: 1.25rem !important; /* 20px */
}
.c-align{
    align-items: center !important;
}

@media print {
    .hidden-print {
        display: none !important;
    }
}
