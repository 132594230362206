@import url('https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi&family=Titan+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700;800&display=swap');

html {
    overflow-x: hidden
}

.swal2-popup {
    font-family: 'Titan One', cursive;
}

body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.timing {
    background-color: rgb(250, 254, 115);
    border-radius: 2px;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.timer-wrapper {
    display: flex;
    justify-content: center;
}

.timer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-family: 'Roboto Slab', cursive;
    color: grey;
    font-weight: bold;
}

.time {
    text-align: center;
}

.value {
    font-size: 60px;
    font-family: 'Roboto Slab', cursive;
    font-weight: bold;
}

.PPT-Questions {
    border-radius: 4px;
    /* border: 8px double gray; */
    border: 4px solid gray;
    padding: 0px 6px;
    font-weight: bold;
    width: fit-content;
}

.single_question {
    display: flex;

}

.single_question p,
.single_question div {
    /* font-weight: bolder; */
    font-family: "Noto Serif Devanagari" !important;

}

.single_question img {
    border-radius: 2px;
}

.options {
    /* display: flex; */
    justify-content: start;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 4px;
    /* border: 8px double white; */
    border: 4px solid gray;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
}

.individual-options {
    margin: 4px;
    font-weight: bold;
    border-radius: 2px;
    padding: 8px 12px;
    box-shadow: #fff 0px 7px 29px 0px;
    border: 1px solid white;
    transition-property: background-color;
    transition-duration: 2s;
    font-family: "Noto Serif Devanagari" !important;
}

.individual-options img {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.actions_bar {
    padding: 4px 10px;
    position: fixed;
    display: flex;
    left: 6px;
    bottom: 6px;
    background-color: white;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, .0001) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}


/* Modal Content Css styling start */
.allElement {
    background-color: white;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.allElement p {
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 4px;
    padding: 6px 8px;
    margin: 4px !important;
    text-align: center;
    cursor: pointer;
}

/* Modal Content Css styling End */

/* width */
::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(226, 226, 226);
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(219, 219, 219);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(196, 196, 255);
}

#react-modal-image-img{
    width: auto !important;
    height: 99vh;
}
